// eslint-disable-next-line import/prefer-default-export
export const defineInfoCellStyles = ({ theme, isFirstElement, isPlanDescription, }) => {
    if (isFirstElement && isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.25}px 0`,
        };
    }
    if (isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.5}px 0`,
            lineHeight: '24px',
        };
    }
    if (isFirstElement) {
        return {
            padding: `${theme.spacing_sizes.xm}px 0`,
            lineHeight: '18px',
        };
    }
    return {
        padding: `${theme.spacing_sizes.xs * 1.75}px 0`,
        lineHeight: '20px',
    };
};
