import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as CheckedIcon } from '@icons/wolfkit-solid/checkmark-circle-solid.svg';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-solid/xmark-with-cross-circle-solid.svg';
import Icon from '@shared/ui/icons/Icon';
const AvailableIcon = styled(CheckedIcon)(props => ({
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const UnavailableIcon = styled(XMarkIcon)(() => ({
    circle: {
        fill: '#D6DCE0',
    },
    path: {
        stroke: '#EFF1F3',
    },
}));
const AvailabilityBadge = ({ isAvailable = undefined, }) => (isAvailable ? (_jsx(Icon, { IconComponent: AvailableIcon, size: 24 })) : (_jsx(Icon, { IconComponent: UnavailableIcon, size: 24 })));
export default AvailabilityBadge;
