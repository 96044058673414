import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { formatDateDefault } from '@utils/date-time';
import Banner from '@shared/ui/display/Banner';
const SubscriptionExpirationBanner = ({ subscription, subscriptionPlan, }) => {
    var _a;
    const { t, i18n } = useTranslation();
    const bannerText = t('settings.tabs.subscription.subscription_plan.subscription_expiration', {
        planName: (_a = subscriptionPlan.metadata) === null || _a === void 0 ? void 0 : _a.title[i18n.language],
        date: formatDateDefault(new Date(subscription.currentPeriodEnd), i18n.language)
    });
    return (_jsx(Banner, { variant: 'info', text: bannerText, hideCloseButton: true }));
};
export default SubscriptionExpirationBanner;
