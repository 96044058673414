import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import { PaymentMethodFormType, closeSidebar as closeSidebarAction, } from '@entities/current-user';
import { useAppSelector } from '@store/Hooks';
import { ContainerColumn } from '@components/styled';
import SubscriptionPlans from '@pages/settings-subscription/ui/SettingsSubscription/SubscriptionPlans';
import PaymentMethod from '@pages/settings-subscription/ui/SettingsSubscription/PaymentMethod';
import PaymentInformation from '@pages/settings-subscription/ui/SettingsSubscription/PaymentInformation';
import AddNewPayment from '../sidebars/AddNewPayment';
import EditPaymentMethod from '../sidebars/EditPaymentMethod';
import ChangeSubscription from '../sidebars/ChangeSubscription';
import useSubscriptionParams from '../../lib/useSubscriptionParams';
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: `${props.theme.spacing_sizes.m * 2}px`,
}));
const SettingsSubscription = () => {
    const dispatch = useDispatch();
    const { paymentMethodFormType, isPaymentFormOpen } = useAppSelector(state => state.user);
    useSubscriptionParams();
    const handleSidebarClose = useCallback(() => {
        dispatch(closeSidebarAction());
    }, [dispatch]);
    const displayedSidebarElement = useMemo(() => {
        switch (paymentMethodFormType) {
            case PaymentMethodFormType.AddPaymentMethod:
                return _jsx(AddNewPayment, { onClose: handleSidebarClose });
            case PaymentMethodFormType.EditPaymentMethod:
                return _jsx(EditPaymentMethod, { onClose: handleSidebarClose });
            case PaymentMethodFormType.ChangeSubscriptionPlan:
                return _jsx(ChangeSubscription, { onClose: handleSidebarClose });
            default:
                return null;
        }
    }, [paymentMethodFormType, handleSidebarClose]);
    return (_jsxs(Container, { children: [_jsx(SubscriptionPlans, {}), _jsx(PaymentMethod, {}), _jsx(PaymentInformation, {}), _jsx(Sidebar, { isOpen: isPaymentFormOpen, children: displayedSidebarElement })] }));
};
export default SettingsSubscription;
